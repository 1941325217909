import * as React from 'react';

import type { JurisdictionHotlineFooterOverride } from '@dnc/baseline/contentful/types';
import type { Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import type { Option } from '@dnc/baseline/utils/option';

import { Header } from './header';

export type LayoutProps = {
  jurisdiction: Option<Jurisdiction>;
  customVoterHotline: Option<string>;
  hotlineFooterOverride: Option<JurisdictionHotlineFooterOverride>;
  locateLayout?: boolean;
  children?: React.ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({
  jurisdiction,
  customVoterHotline,
  hotlineFooterOverride,
  locateLayout,
  children,
}) => {
  const header = (
    <Header
      jurisdiction={jurisdiction}
      locateLayout={locateLayout}
      customVoterHotline={customVoterHotline}
      hotlineFooterOverride={hotlineFooterOverride}
    />
  );
  if (locateLayout) {
    return (
      <div className="page-wrap">
        {header}
        <div id="main-content" className="content-wrap">
          <section className="locate__container">{children}</section>
        </div>
      </div>
    );
  }
  return (
    <div className="column-wrapper">
      <div className="left-column two-col">
        <div className="content-wrapper">{header}</div>
      </div>
      <div id="main-content" className="right-column two-col">
        <div className="content-wrapper">{children}</div>
      </div>
    </div>
  );
};
