/* eslint-disable */
// TODO: Review implementation and enable linting

// Tries to wait for a promise, but if it takes too long aborts
export function attempt<T>(p: Promise<T>, timeout = 1500): Promise<void> {
  let called = false;
  const promise: Promise<void> = new Promise(async (resolve) => {
    const wait = setTimeout(() => {
      if (!called) {
        resolve();
      }
    }, timeout);
    await p;
    clearTimeout(wait);
    if (!called) {
      called = true;
      resolve();
    }
  });

  return promise;
}
