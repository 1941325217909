/**
 * Shared component for handling link behavior and click tracking
 *
 * `analyticsKey`: sent to GoogleAnalytics when link is clicked
 *
 * `linkOptions`: Includes external link icon and attempts to open URL in new tab
 *                when linkOptions.target === _blank
 *
 * `beforeRedirect`: use callback to trigger action before redirecting
 */

import cx from 'classnames';
import * as React from 'react';
import { Link } from 'react-router';

import { attempt } from '@dnc/baseline/utils/attempt';

import Check from '../assets/check.svg?react';
import ExternalLinkIcon from '../assets/external-link.svg?react';

import { useAnalytics } from './AnalyticsProvider';

export type ActionButtonType =
  | 'request-ballot'
  | 'locate'
  | 'custom-button-a'
  | 'custom-button-b'
  | 'confirm'
  | 'register'
  | 'in-person-info'
  | 'voter-education'

  // Only referenced by the unused registration contact capture flow
  | 'sent_sos'
  | 'sent_nvra';

export type ActionButtonProps = {
  buttonClass?: string;
  children: React.ReactNode;
  url: string;
  type: ActionButtonType;
  linkOptions?: {
    rel?: string;
    target?: string;
  };
  checkmark?: boolean;
  beforeRedirect?: () => void;
  disabled?: boolean;
};

const ActionButton: React.FC<ActionButtonProps> = ({
  buttonClass = 'button--secondary',
  checkmark = false,
  children,
  url,
  type,
  linkOptions,
  beforeRedirect,
  disabled,
}) => {
  const analytics = useAnalytics();
  const isExternalLink = linkOptions?.target === '_blank';

  const onClick = (ev: React.MouseEvent) => {
    if (disabled) {
      ev.preventDefault();
      return;
    }

    if (beforeRedirect) {
      beforeRedirect();
    }

    analytics.recordConversion({
      type: 'ActionOptionsClick',
      analyticsKey: type,
    });

    attempt(
      analytics.clicked({
        category: 'FrontDoor',
        target: type,
        isExternal: isExternalLink,
      })
    );
  };

  return (
    <Link
      to={url}
      className={cx('action-item', buttonClass, {
        'border-gray-500 border-2 text-gray-500 bg-white cursor-default hover:bg-[inherit] active:bg-[inherit]':
          disabled,
      })}
      onClick={onClick}
      rel={linkOptions?.rel || ''}
      target={linkOptions?.target || '_self'}
      aria-disabled={disabled}
    >
      {checkmark && (
        <div className="check-mark">
          <Check />
        </div>
      )}

      {children}

      {isExternalLink && <ExternalLinkIcon className="external-link-icon" />}
    </Link>
  );
};

export { ActionButton };
