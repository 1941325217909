import { QueryClient } from '@tanstack/react-query';

import { type Jurisdiction, Jurisdictions } from '../data/jurisdictions';

import type { Option } from '../utils/option';

import type { GeoIpService } from './vis-service';

export class JurisdictionService {
  private readonly geoip: GeoIpService;
  private readonly queryClient: QueryClient;

  constructor({
    geoip,
    queryClient,
  }: {
    geoip: GeoIpService;
    queryClient: QueryClient;
  }) {
    this.geoip = geoip;
    this.queryClient = queryClient;
  }

  async getJurisdiction(): Promise<Option<Jurisdiction>> {
    return (
      (await this.queryClient.fetchQuery({
        staleTime: Number.MAX_SAFE_INTEGER,
        queryKey: ['getJurisdiction'],
        queryFn: async () => {
          const geoInfo = await this.geoip.getGeoInfo();
          if (!geoInfo) {
            return null;
          }

          const region = geoInfo.region_code;
          if (Object.keys(Jurisdictions.allStringsEn).includes(region)) {
            return region as Jurisdiction;
          }

          return null;
        },
      })) ?? undefined
    );
  }
}

export type JurisdictionProvider = {
  jurisdiction: JurisdictionService;
};
