import type { State } from '../data/jurisdictions';

// These IDs point to JurisdictionConfig object
export const ProductionJurisdictionConfigEntries: { [key in State]: string } = {
  AK: '75IoY8ovvwk1pksTBLDRMV',
  AL: '3s30CnvUdJJwDtkgMjSZjD',
  AR: '4GaeeGMeRHOhTLnlwBY7Mo',
  AZ: '7eqSxEP6wtDV94upnR6RYG',
  CA: '5deh6GiwOLJCPsgSp1sl0I',
  CO: '4lLldryci48Qj2fyWyHTWT',
  CT: '5MPQvNaHr5B53T3L9ZGxCv',
  DC: '3tWAmNhxXSnIT7aHWxHwDP',
  DE: '2kmGh79HnLwTkE81iMDRyE',
  FL: '2j24XMNy8pMccei2jKQ1Er',
  GA: '4GqQx3QmVRSBTiIRhaTuwd',
  HI: '4V4U0Waz8D5TkhOIJCtIYi',
  IA: 'vLq2xF2FSEwDs4asyFTSp',
  ID: '3LgxE9NnosMjBzckswl7ob',
  IL: '2dKrtdZYKSOORGpmvZH9k3',
  IN: 'vPV4Y4X5JYe5OlSgm1ISS',
  KS: '4S2IrG9lt7sPyFOvbsDUwI',
  KY: 'f7eYTFFL5U7qlq4pijtcJ',
  LA: '66yWPhw7qLcY21g5mxmvAY',
  MA: '7jJgxOKcKQ7r5a050I4FNk',
  MD: '7shDcoDRJzqFjLAwGiQ1jh',
  ME: '4KZDs7N0r8p78j3sPmztfK',
  MI: '4hIAMvYujFOF2QgQ16wGJC',
  MN: '5Q5kp6Rid2W0HReJYeAUnE',
  MO: '7FWOyVbxJ7UlJtFHR42sP9',
  MS: '5wmvVJbFqoU6sW3YyFDwgt',
  MT: '5BeDaEVBLwW012phhbBOM2',
  NC: '7d91iJZfCK7aciY0BbpOt4',
  ND: '1Xo11xoC2snITO2LBibNcP',
  NE: '2FNQEr4JDwNnmAB2tlPm1a',
  NH: '1OhgW7Z2zdke7hTDjk99Hj',
  NJ: '3HJ83JQhKxszTflLZW4qp1',
  NM: '3u4vqwC0zCIU0NCXxi46Ix',
  NV: '77wQNHTT7xDzGuCZAryIjI',
  NY: '4W1OpvXCiKXMOY9rT3LZVh',
  OH: '9HgZu09tst25NbYVAQYWl',
  OK: '2ZAxn1gciNa1nvff06Rhb2',
  OR: '5ZUPlEgPXohy9JUc4Y42hH',
  PA: '2uO2u41XkmQCMmN8dS8GPE',
  RI: '337DIFi3ZjKK2aeaRTMHKY',
  SC: '7L3U4mEpIobDRqwwyebRIm',
  SD: '25PSs3houmGkjDD9vVIcgN',
  TN: '1AYsZ2oqHtUYxYtsV0YfSm',
  TX: '6zvoNSqxK6CYWZHq5rHbd6',
  UT: '3f3JRDGcBH8mE5SOA9qsck',
  VA: '17CayLJad44pECgw6tJhLY',
  VT: '4M877ERkb080DK8uaXasJC',
  WA: '29y47llAettE0yzj7a41Ig',
  WI: '4a6SLCbLGr70t8eBGa3lfZ',
  WV: '30r80bn1sd3XJwOpq00vMQ',
  WY: '33ZoKtKpDsZXdQxdWFbCYE',
};

// These are TestingJurisdictionConfig Entries
// TestingJurisdictionConfig entries are copies of
// JurisdictionConfig objects safe for testing/experimentation
export const TestingJurisdictionConfigEntries: { [key in State]: string } = {
  AK: '1rj3oDrc1oHNcsMwd6W4cz',
  AL: '6YhvbqgsOX4VlIQXJrxlmm',
  AR: '4EQvU2O7oSaV2KtEQqE7gW',
  AZ: '43SLeyew7z7oYIvJchCcEw',
  CA: '7hIHDhgKn8PqkS6ofjTQbc',
  CO: '1XMDXYyRcDRMiQROyoPk29',
  CT: '4I6aO804Ctnfcrpiasymc9',
  DC: '2pBRtJPPCBK9gp4j9GiSaA',
  DE: '2f40SJBLBJAISSydXyk4cF',
  FL: '56DWtqDeKWpWRF8OEPrZtN',
  GA: '7jc2kLEYV1Fkq4xBeo4Xea',
  HI: '29ElnEemnx3G0EDZSVcU5',
  IA: '4wmoUABcTwaEplnvOR1BFz',
  ID: '598XUNUFuNluhY4spzYQ8t',
  IL: '3Q8rDygyHxC0T33r1Hqa0m',
  IN: '42EwIXTRNuhjGo60nMggi7',
  KS: '3xLHcXoyptKLTkB1qQwM8z',
  KY: '4A0UZROSsp3uGMKxjkpnOV',
  LA: '6tfFNa4ipOCGQstUVHs19x',
  MA: '4XWNpt36sW4nlfPliLZVZH',
  MD: '1J4f5ptLPBBZCjCrTD9R8q',
  ME: '1GuhKA22FlrGwFO1cBC143',
  MI: '5Lgj5ltX3oJx2q0QkaMt9A',
  MN: '43wEh4pjOtirMLFiGftoz6',
  MO: 'HWeSG7DQIBebxCmVPiZPa',
  MS: '3u3w1GsCckppTSFXBz5314',
  MT: '7d3SAOCq6SufEfWAvxXRtL',
  NC: '2nuysGbOrrOhd91dwnOM1g',
  ND: '6SB5TXs3QHmLJ8qocgBg6J',
  NE: 'EotGejXL0h5rfNdG9QSmu',
  NH: '7dgcED9FrJPOWQTRcDNsdJ',
  NJ: '4DA6EMP3UNdAzSl9ygVkHs',
  NM: '6PRBSv8A8u0VdnFaz1A0tV',
  NV: '55KvwWaZfRnekF8hj1lU81',
  NY: '4pkrzYJylH4PpUOgNti23B',
  OH: '7xpHaCgSUTQySI2khWgcJd',
  OK: '6qgocTe0oNOuElUWZGlg6C',
  OR: 'aaWXbOXQ1Ctbgz1VWfiiw',
  PA: '21IzDfjylyjowpUXw2pXuZ',
  RI: '3zhunLNzduBMQ87rZ0yVAU',
  SC: '1zFqXgcT6MFr4nfc5zJbqr',
  SD: '3SU29DVUf1k0RKk8ipPeEf',
  TN: '4NsbGIDNBeM61QW4L7wvsu',
  TX: '4QcRh8WHkGwhSfEFp3t8zU',
  UT: '4iw9VdxUrMEskqwSultRiH',
  VA: 'b272MLNDDHFU4UENyjFbM',
  VT: '7z51eGCXvkT7SaRAVBqZyg',
  WA: '2aHwIbiz3xR6qU5aVqQ82p',
  WI: '4LTqZjtVCQ7xNKkhHTo1vA',
  WV: '1BVzNPCe70PM2b3qOfshP6',
  WY: 'Iwtw1AnJKyUmVe8vcwRZI',
};
