import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import type { JurisdictionConfig } from '@dnc/baseline/contentful/types';
import { type Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import { DEFAULT_LOCALES } from '@dnc/baseline/utils/localization';
import type { Option } from '@dnc/baseline/utils/option';

import { Services } from '../../services/services';
import { UrlHelper } from '../../services/url-helper';
import { AnalyticsProvider } from '../AnalyticsProvider';
import { StateConfirmation } from '../StateConfirmation';

import { Page } from './page';

const VepBackup = React.lazy(() =>
  import('./vepbackup').then(({ VepBackup }) => ({ default: VepBackup }))
);

/**
 * Wrapper around {@link Page} that:
 *
 * - Sets up a {@link AnalyticsProvider} and passes it the props to keep it
 *   updated.
 * - Loads sitewide alerts via a react-query hook.
 * - Makes a {@link JURISDICTION_CONTEXT} `Provider`.
 */
export const ConnectedPage: React.FunctionComponent<
  {
    services: Services;
    jurisdictionConfig: Option<JurisdictionConfig>;
  } & Omit<
    React.ComponentProps<typeof Page>,
    | 'sitewideAlerts'
    | 'jurisdictionAlerts'
    | 'supportedLocales'
    | 'priorityLocales'
    | 'customVoterHotline'
    | 'hotlineFooterOverride'
  >
> = ({ children, services, jurisdiction, jurisdictionConfig, ...props }) => {
  const location = useLocation();
  const urlHelper = UrlHelper.fromLocation(location);
  const contentfulParams = urlHelper.parseContentfulParams();

  // We load these via useQuery so that we don’t block first render on getting
  // them.
  const sitewideAlerts = useQuery(
    services.voterEducation.sitewideAlertsQueryOptions(
      !!contentfulParams.preview
    )
  );

  return (
    <AnalyticsProvider
      analytics={services.analytics}
      location={location}
      jurisdiction={jurisdiction}
    >
      <Page
        jurisdiction={jurisdiction}
        sitewideAlerts={sitewideAlerts.data?.sitewideAlerts}
        jurisdictionAlerts={jurisdictionConfig?.jurisdictionAlert}
        supportedLocales={
          jurisdictionConfig?.supportedLocales ?? DEFAULT_LOCALES
        }
        priorityLocales={jurisdictionConfig?.priorityLocales ?? DEFAULT_LOCALES}
        customVoterHotline={jurisdictionConfig?.voterHotline}
        hotlineFooterOverride={jurisdictionConfig?.hotlineFooterOverride}
        {...props}
      >
        {children}
      </Page>
    </AnalyticsProvider>
  );
};

/**
 * Enforces that a jurisdiction has been selected and a config has been loaded.
 *
 * If the former is not true, renders a {@link StateConfirmation} instead of
 * children. If the latter is not true, renders the VEP Widget (which uses VEP
 * API to get its data).
 *
 * Otherwise it calls its child function with the `jurisdiction` and
 * `jurisdictionConfig`.
 *
 * When the user selects a jurisdiction via this component, the selection is set
 * by doing a history replace with the new jurisdiction in the `state` query
 * parameter.
 */
export const EnforceJurisdictionSelection: React.FunctionComponent<{
  jurisdiction: Option<Jurisdiction>;
  jurisdictionConfig: Option<JurisdictionConfig>;
  children: (props: {
    jurisdiction: Jurisdiction;
    jurisdictionConfig: JurisdictionConfig;
  }) => React.ReactNode;
}> = ({ jurisdiction, jurisdictionConfig, children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const updateJurisdiction = (j: Jurisdiction) => {
    navigate(
      UrlHelper.changeLocationParams(location, {
        [UrlHelper.JURISDICTION_PARAM]: j,
      }),
      {
        replace: true,
        preventScrollReset: true,
      }
    );
  };

  if (!jurisdiction) {
    return (
      <StateConfirmation
        jurisdiction={jurisdiction}
        updateJurisdiction={updateJurisdiction}
      />
    );
  } else if (!jurisdictionConfig) {
    // Having jurisdiction but no jurisdictionConfig is an indication that the
    // loader was unable to get info from Contentful. Since this is rare, we
    // lazy-load the <VepBackup> component so we don’t have to include it in the
    // complete bundle.
    return (
      <React.Suspense fallback={<div className="loading-spinner" />}>
        <VepBackup
          jurisdiction={jurisdiction}
          updateJurisdiction={updateJurisdiction}
        />
      </React.Suspense>
    );
  } else {
    return children({ jurisdiction, jurisdictionConfig });
  }
};
