import * as React from 'react';

import {
  LocaleToLanguageName,
  type ActiveLocale,
} from '@dnc/baseline/utils/localization';

type LocaleSelectorProps = {
  displayLocale: ActiveLocale;
  onClick: () => void;
};

export const LocaleSelector: React.FC<LocaleSelectorProps> = ({
  displayLocale,
  onClick,
}) => (
  <button
    className="locale-selector"
    onClick={onClick}
    type="button"
    lang={displayLocale}
  >
    {LocaleToLanguageName[displayLocale]}
  </button>
);
