import moment from 'moment';

import type { JurisdictionConfig } from '@dnc/baseline/contentful/types';
import { type Jurisdiction } from '@dnc/baseline/data/jurisdictions';

import { Services } from '../services/services';
import { UrlHelper } from '../services/url-helper';

/**
 * Helper for loading a {@link JurisdictionConfig} from Contentful. Uses the URL
 * to determine if we’re doing a test config or preview data.
 *
 * Throws if the loading failed.
 */
export async function fetchJurisdictionConfig(
  services: Pick<Services, 'voterEducation'>,
  urlHelper: UrlHelper,
  jurisdiction: Jurisdiction,
  now = moment()
): Promise<JurisdictionConfig> {
  const contentfulParams = urlHelper.parseContentfulParams();

  const jurisdictionConfig =
    await services.voterEducation.fetchJurisdictionConfig(
      jurisdiction,
      !!contentfulParams.preview,
      !!contentfulParams.test_config,
      now
    );

  if (!jurisdictionConfig) {
    throw new Error(`Failure loading jurisdiction: ${jurisdiction}`);
  }

  return jurisdictionConfig;
}
