import * as React from 'react';

import ActionOptions from '../components/ActionOptions';
import {
  ConnectedPage,
  EnforceJurisdictionSelection,
} from '../components/pages/ConnectedPage';
import {
  type OptionalLoadedJurisdictionInfo,
  loadJurisdictionFromSearchQuery,
} from '../loaders/loadJurisdictionFromSearchQuery';

import { getGlobalServices } from '../services/services';

import type { Route } from './+types/_index';

export async function clientLoader(
  arg: Route.ClientLoaderArgs
): Promise<OptionalLoadedJurisdictionInfo> {
  const services = getGlobalServices();

  return loadJurisdictionFromSearchQuery(services, arg);
}

export default function HomePage({
  loaderData,
}: Route.ComponentProps): React.ReactNode {
  const services = getGlobalServices();

  return (
    <ConnectedPage
      services={services}
      jurisdiction={loaderData.jurisdiction}
      jurisdictionConfig={loaderData.jurisdictionConfig}
    >
      <EnforceJurisdictionSelection
        jurisdiction={loaderData.jurisdiction}
        jurisdictionConfig={loaderData.jurisdictionConfig}
      >
        {({ jurisdiction, jurisdictionConfig }) => (
          <ActionOptions
            jurisdiction={jurisdiction}
            activeElection={jurisdictionConfig.electionInfo}
            voterRegConfig={jurisdictionConfig.registrationConfig}
            voterEdPageConfig={jurisdictionConfig.vepConfig}
            pollingLookupConfig={jurisdictionConfig.pollingLocationLookupConfig}
            ballotRequestConfig={jurisdictionConfig.ballotRequestConfig}
            jurisdictionConfigCustomButtons={
              jurisdictionConfig.customLandingPageButtons
            }
          />
        )}
      </EnforceJurisdictionSelection>
    </ConnectedPage>
  );
}
