import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import type { Option } from '@dnc/baseline/utils/option';
import { JurisdictionSelect } from '@dnc/shared-components/jurisdiction-select';

type StateConfirmationProps = {
  jurisdiction: Option<Jurisdiction>;
  updateJurisdiction: (jurisdiction: Jurisdiction) => void;
};

/**
 * This UI is only a backup for allowing a user to pick or confirm their state,
 * if the geolocation API call happens to fail.
 */
export const StateConfirmation: React.FC<StateConfirmationProps> = ({
  jurisdiction,
  updateJurisdiction,
}) => {
  const intl = useIntl();

  return (
    // Some bottom margin to vertically center this a bit higher, which looks
    // better given the whitespace from the language picker.
    <div className="state-confirmation mb-14">
      <h1>
        <FormattedMessage
          defaultMessage="Where do you live?"
          id="stateConfirmation.header"
          tagName="span"
        />
      </h1>
      <p>
        <FormattedMessage
          defaultMessage="Different states vote in different ways. We’ll help you understand how to vote in your state."
          id="stateConfirmation.subheader"
          tagName="span"
        />
      </p>
      <br />
      <JurisdictionSelect
        name="jurisdiction"
        className="select"
        value={jurisdiction || ''}
        placeholder={intl.formatMessage({
          defaultMessage: 'Select your state…',
          id: 'QuXvxg',
          description: 'Placeholder in a state selection drop-down',
        })}
        onChange={(e) => {
          updateJurisdiction(e.currentTarget.value as Jurisdiction);
        }}
      />
    </div>
  );
};
