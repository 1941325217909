import * as React from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';

import type { JurisdictionLocaleSupport } from '@dnc/baseline/contentful/types';
import {
  LocaleToLanguageName,
  defaultHostLocale,
  type ActiveLocale,
} from '@dnc/baseline/utils/localization';

import { UrlHelper } from '../services/url-helper';

import { AdditionalLocaleSelectors } from './AdditionalLocaleSelectors';
import { LocaleSelector } from './LocaleSelector';

type SiteLanguageMenuProps = {
  currentLocale: ActiveLocale;
  supportedLocales: JurisdictionLocaleSupport;
  priorityLocales: JurisdictionLocaleSupport;
};

// value taken src/scss/layout.scss : two-col-breakpoint
// two-col-breakpoint refers to src/scss/1.Base/variables.scss : breakpoint-md
const TWO_COLUMN_BREAKPOINT = 1000;
const windowShowsOneColumn = () =>
  // window check because we might be running on the server w/ SSR
  typeof window !== 'undefined' && window.innerWidth < TWO_COLUMN_BREAKPOINT;

export const SiteLanguageMenu: React.FC<SiteLanguageMenuProps> = ({
  currentLocale,
  supportedLocales,
  priorityLocales,
}) => {
  const intl = useIntl();
  const [isOneColumnView, setIsOneColumnView] =
    React.useState(windowShowsOneColumn);
  const supportedLocaleOptions = React.useMemo(() => {
    return supportedLocales.filter((locale) => locale !== currentLocale);
  }, [supportedLocales, currentLocale]);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const handleResize = () => setIsOneColumnView(windowShowsOneColumn);

    window.addEventListener('resize', handleResize);
    // clean up event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const updateLocaleSelection = (selectedLocale: ActiveLocale) => {
    navigate(
      UrlHelper.changeLocationParams(location, {
        // We remove the locale param if the user is switching back to the
        // site’s default locale, just to keep the URL cleaner.
        [UrlHelper.LOCALE_PARAM]:
          selectedLocale === defaultHostLocale() ? undefined : selectedLocale,
      }),
      { replace: true, preventScrollReset: true }
    );
  };

  /**
   * For two column layout (on larger tablets/desktop),
   * all locales are displayed as `LocaleSelectors` equally, regardless of
   * `priorityLocales` configuration.
   */
  const makeTwoColumnSupportedLocaleSelectors = () => {
    return supportedLocaleOptions.map((localeOption) => {
      return (
        <LocaleSelector
          displayLocale={localeOption}
          key={localeOption}
          onClick={() => updateLocaleSelection(localeOption)}
        />
      );
    });
  };

  /**
   * For one column layout (on mobile),
   * uses `priorityLocales` to determine which options
   * should appear as `LocaleSelectors`,
   * outside of `AdditionalLocaleSelectors`.
   * `supportedLocales` that are not in `priorityLocales`
   * appear within `AdditionalLocaleSelectors`.
   */
  const makeOneColumnSupportedLocaleSelectors = () => {
    const priorityOptions = supportedLocaleOptions
      .filter((localeOption) => priorityLocales.includes(localeOption))
      .map((localeOption) => {
        return (
          <LocaleSelector
            displayLocale={localeOption}
            key={localeOption}
            onClick={() => updateLocaleSelection(localeOption)}
          />
        );
      });
    const additionalLocaleOptions = supportedLocaleOptions.filter(
      (localeOption) => !priorityLocales.includes(localeOption)
    );
    if (additionalLocaleOptions.length > 0) {
      return (
        <>
          {priorityOptions}
          <AdditionalLocaleSelectors
            additionalLocales={additionalLocaleOptions}
            currentLocale={currentLocale}
            updateSelectedLocale={(localeOption: ActiveLocale) =>
              updateLocaleSelection(localeOption)
            }
          />
        </>
      );
    }
    // if there aren't any non-priority/additional options
    // to display in 'More' dropdown, i.e. if DEFAULT_LOCALES
    // are both the supportedLocales AND priorityLocales,
    // only return the priorityOptions
    return priorityOptions;
  };
  return (
    <menu
      className="site-language-menu"
      aria-label={intl.formatMessage({
        defaultMessage: 'Site Language',
        description: 'Used as an aria-label for the SiteLanguageMenu',
        id: 'xzRUsk',
      })}
      // uses the lang attribute so the aria-label will be read correctly
      // in the desired language
      lang={currentLocale}
    >
      <span
        className="selected-language"
        // uses the lang attribute so "in <LANGUAGE>" will be read correctly
        // in the desired language
        lang={currentLocale}
      >
        {LocaleToLanguageName[currentLocale]}
      </span>
      {!isOneColumnView
        ? makeTwoColumnSupportedLocaleSelectors()
        : makeOneColumnSupportedLocaleSelectors()}
    </menu>
  );
};
