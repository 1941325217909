import { type LoaderFunctionArgs } from 'react-router';

import type { JurisdictionConfig } from '@dnc/baseline/contentful/types';
import type { Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import type { Option } from '@dnc/baseline/utils/option';

import { Services } from '../services/services';
import { TypedUrlHelper } from '../services/url-helper';

import { enforceJurisdictionLocale } from './enforceJurisdictionLocale';
import { fetchJurisdictionConfig } from './fetchJurisdictionConfig';
import { fetchJurisdictionFromSearchQueryOrGeo } from './fetchJurisdictionFromSearchQueryOrGeo';

/**
 * Type for when we may or may not have a jurisdiction, and we may or may not
 * have loaded its config.
 */
export type OptionalLoadedJurisdictionInfo =
  | {
      jurisdiction: undefined;
      jurisdictionConfig: undefined;
    }
  | {
      jurisdiction: Jurisdiction;
      jurisdictionConfig: Option<JurisdictionConfig>;
    };

/**
 * Determines the jurisdiction and loads the jurisdictionConfig from the "state"
 * query parameter. If it’s not provided, uses {@link JurisdictionService} to
 * look it up via geo-ip.
 *
 * If the value of the `state` parameter is not a valid jurisdiction, redirects
 * to the same URL, but without a `state` parameter.
 *
 * Respects a secret `no_geo` query parameter that prevents it from loading
 * geo-ip data, for easier testing of those cases.
 *
 * This may return a `jurisdiction` but not a `jurisdictionConfig` if there was
 * an error loading from Contentful, in which case routes may use the VEP widget
 * fallback.
 */
export async function loadJurisdictionFromSearchQuery(
  services: Pick<Services, 'voterEducation' | 'jurisdiction' | 'sentry'>,
  { request }: LoaderFunctionArgs
): Promise<OptionalLoadedJurisdictionInfo> {
  const urlHelper = TypedUrlHelper.fromRequestUrl(request.url, {
    enforce: true,
  });

  const jurisdiction = await fetchJurisdictionFromSearchQueryOrGeo(
    services,
    urlHelper
  );

  if (!jurisdiction) {
    return {
      jurisdiction: undefined,
      jurisdictionConfig: undefined,
    };
  } else {
    let jurisdictionConfig: Option<JurisdictionConfig> = undefined;

    try {
      jurisdictionConfig = await fetchJurisdictionConfig(
        services,
        urlHelper,
        jurisdiction
      );
    } catch (error: any) {
      console.error(`Failure loading jurisdiction: ${jurisdiction}`, error);

      // Log the error in Sentry
      services.sentry.record(error);
    }

    if (jurisdictionConfig) {
      enforceJurisdictionLocale(
        request.url,
        urlHelper.getLocale(),
        jurisdictionConfig
      );
    }

    return {
      jurisdiction,
      jurisdictionConfig,
    };
  }
}
