import { type Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import type { Option } from '@dnc/baseline/utils/option';

import { Services } from '../services/services';
import { TypedUrlHelper, UrlHelper } from '../services/url-helper';

/**
 * Returns either the "state" parameter from the query parameters, or uses geo
 * IP to look up the user’s state.
 *
 * Respects the no_geo param for testing IWV without the /geo-ip call
 * succeeding.
 *
 * Note this does not care at all about _path_ params, such as those used by the
 * `/votinginfo/XX` routes.
 */
export async function fetchJurisdictionFromSearchQueryOrGeo(
  services: Pick<Services, 'jurisdiction'>,
  urlHelper: TypedUrlHelper
): Promise<Option<Jurisdiction>> {
  if (urlHelper.getJurisdiction()) {
    return urlHelper.getJurisdiction()!;
  } else if (!urlHelper.siteParams[UrlHelper.NO_GEO_PARAM]) {
    // No state param, so try geo-ip
    return await services.jurisdiction.getJurisdiction();
  } else {
    return undefined;
  }
}
