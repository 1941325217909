import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import * as React from 'react';
import { Link, useLocation } from 'react-router';

import type { ContentfulRichTextObject } from '@dnc/baseline/contentful/types';
import { attempt } from '@dnc/baseline/utils/attempt';
import { getLocalizedRichText } from '@dnc/baseline/utils/localization';

import DarkBlueExternalLinkIcon from '../../assets/external-link-dark-blue.svg?react';
import ExternalLinkIcon from '../../assets/external-link.svg?react';
import { UrlHelper } from '../../services/url-helper';
import { useAnalytics } from '../AnalyticsProvider';

import { LocaleContext } from './LocaleContext';

type LocalizedRichTextProps = {
  richTextBlock: ContentfulRichTextObject | undefined;
  className?: string;
  imgAssetClassName?: string;
  innerParagraphStyleOverride?: string;
};

const LocalizedRichText: React.FC<LocalizedRichTextProps> = ({
  richTextBlock,
  className,
  imgAssetClassName,
  innerParagraphStyleOverride,
}) => {
  const locale = React.useContext(LocaleContext);
  const location = useLocation();
  const analytics = useAnalytics();

  const isAlertBanner = className?.includes('alert-bar');

  const urlHelper = UrlHelper.fromLocation(location);

  const options = {
    /* This options object works with
    the documentToReactComponents() function
    from @contentful/rich-text-react-renderer
    
    By using this object, devs can apply custom
    styling to specific parts of a rich text
    entry.
    
    The UI in Contentful only accepts the href
    and text content for a url; we need to use
    this object in order to allow the url open
    in a new page with target="_blank".
    
    The (node:any) line does not make Typescript
    happy, however it is necessary to get the
    url rendering to work.
    
    See additional documentation here:
    https://www.npmjs.com/package/@contentful/rich-text-react-renderer
    */
    renderNode: {
      // support optionally overriding the styles of the paragraph
      // rendered by documentToReactComponents()
      [BLOCKS.PARAGRAPH]: (_: any, children: any) => (
        <p className={`${innerParagraphStyleOverride ?? ''}`}>{children}</p>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const nodeData = node.data;
        const { url } = nodeData.target.fields.file.en;
        const altText = nodeData.target.fields.description?.[locale];
        return (
          <img
            src={`https://${url}`}
            alt={altText || ''}
            className={imgAssetClassName}
          />
        );
      },
      [INLINES.HYPERLINK]: (node: any) => {
        // TODO(fiona): Handle <a> tags without href better?
        let href: string = node.data.uri ?? '#';
        let isInternalLink: boolean;

        if (href.startsWith('https://iwillvote.com')) {
          isInternalLink = true;

          // Links in Contentful are hard-coded to direct to production. We remove
          // that so that they’re relative to both localhost and voyavotar.com.
          href = href.replace('https://iwillvote.com', '');
          // Include lang and other parameters we want to preserve.
          href = urlHelper.addSiteParamsToUrl(href);
        } else {
          isInternalLink = false;
        }

        const onClick = () => {
          attempt(
            analytics.clicked(
              isInternalLink
                ? {
                    category: 'FrontDoor',
                    target: 'register',
                    isExternal: false,
                  }
                : {
                    category: 'Outbound Link',
                    target: 'confirm',
                    isExternal: true,
                  }
            )
          );
        };

        return (
          <Link
            to={href}
            onClick={onClick}
            className="hover:text-blue-100"
            {...(isInternalLink
              ? {}
              : {
                  target: '_blank',
                  rel: 'external noreferrer',
                })}
          >
            {`${node.content[0].value}`}

            {!isInternalLink &&
              (isAlertBanner ? (
                <DarkBlueExternalLinkIcon className="external-link-icon" />
              ) : (
                <ExternalLinkIcon className="ml-2 ltr:ml-2 rtl:ml-0 rtl:mr-2 max-h-4 max-w-4 inline align-baseline fill-current" />
              ))}
          </Link>
        );
      },
    },
  };

  const richTextContent = richTextBlock
    ? getLocalizedRichText(locale, richTextBlock)
    : undefined;
  return (
    <div className={`${className ? className : 'markdown'}`}>
      {richTextContent
        ? documentToReactComponents(richTextContent, options)
        : ''}
    </div>
  );
};

export { LocalizedRichText };
