import { QueryClient } from '@tanstack/react-query';

import {
  AnalyticsService,
  GoogleAnalyticsAdapter,
} from '@dnc/baseline/services/analytics-service';
import {
  ElectionsService,
  type ElectionsProvider,
} from '@dnc/baseline/services/elections-service';
import {
  JurisdictionService,
  type JurisdictionProvider,
} from '@dnc/baseline/services/jurisdiction-service';
import {
  JurisdictionSettingsService,
  type JurisdictionSettingsProvider,
} from '@dnc/baseline/services/jurisdiction-settings-service';
import {
  NullSentryService,
  SentryService,
} from '@dnc/baseline/services/sentry-service';
import {
  VISService,
  type VISProvider,
} from '@dnc/baseline/services/vis-service';
import {
  VoterEducationService,
  type VoterEducationProvider,
} from '@dnc/baseline/services/voter-education-service';

import { PollingPlaceService } from './polling-place-service';

export class Services
  implements
    ElectionsProvider,
    JurisdictionProvider,
    JurisdictionSettingsProvider,
    VISProvider,
    VoterEducationProvider
{
  readonly queryClient: QueryClient;

  readonly analytics: AnalyticsService;

  readonly jurisdiction: JurisdictionService;
  readonly settings: JurisdictionSettingsService;

  readonly sentry: SentryService;

  readonly elections: ElectionsService;

  readonly voterEducation: VoterEducationService;

  readonly vis: VISService;
  readonly pollingPlace: PollingPlaceService;

  constructor() {
    this.queryClient = new QueryClient();

    this.analytics = new AnalyticsService(new GoogleAnalyticsAdapter());

    this.voterEducation = new VoterEducationService({
      queryClient: this.queryClient,
    });

    this.elections = new ElectionsService({
      voterEducationService: this.voterEducation,
    });

    this.vis = new VISService(this.analytics);

    this.jurisdiction = new JurisdictionService({
      geoip: this.vis,
      queryClient: this.queryClient,
    });

    this.sentry = import.meta.env.VITE_SENTRY_DSN
      ? new SentryService(import.meta.env.VITE_SENTRY_DSN)
      : NullSentryService;

    this.settings = new JurisdictionSettingsService();

    this.pollingPlace = new PollingPlaceService({
      electionsService: this.elections,
      visService: this.vis,
      queryClient: this.queryClient,
    });
  }
}

let SERVICES: Services | null = null;

export function getGlobalServices(): Services {
  if (SERVICES === null) {
    SERVICES = new Services();
  }

  return SERVICES;
}
