// Stateless utility functions for parsing and updating AppState from
// location.search
import * as qs from 'query-string';

import { ActiveJurisdictions } from '@dnc/baseline/data/jurisdiction-denylist';
import {
  Jurisdictions,
  type Jurisdiction,
} from '@dnc/baseline/data/jurisdictions';
import type { UTMFields } from '@dnc/baseline/services/analytics-service';
import type { Option } from '@dnc/baseline/utils/option';

// We should do something more clever than just guessing
// like show an actual state picker. The state picker is still visible
// if we guess wrong, so it's not a huge deal.
// See https://democrats.atlassian.net/browse/VOTE-378
export const DEFAULT_JURISDICTION = 'AL';

export type QueryComponent = { [key: string]: Option<string> };

/**
 * Google’s campaign tracking parameters. Note that the first three are
 * required.
 */
export type GaCampaignQueryParameters = {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term?: string;
  utm_content?: string;
};

/**
 * Our internal campaign query parameters.
 *
 * These are read and sent to our 3rd party partners when processing contact
 * information.
 *
 * @see https://docs.google.com/document/d/16AVTWvKlc1Q66nh12X-t6HI_M3LdxeVGE4aZrbQYVU4/edit
 */
export type PartnerCampaignQueryParameters = {
  partner: string;
  partner_campaign: string;
  partner_medium?: string;
  partner_content?: string;
  partner_custom?: string;
  partner_term?: string;
};

/**
 * If the provided string is a state / territory code, returns it (uppercase) if
 * it’s among the {@link ActiveJurisdictions}.
 *
 * Otherwise returns undefined.
 */
export function validatedJurisdiction(
  jurisdiction: Option<string>
): Option<Jurisdiction> {
  const upcasedJurisdiction = (jurisdiction || '').toUpperCase();

  if (
    Jurisdictions.isJurisdiction(upcasedJurisdiction) &&
    ActiveJurisdictions.includes(upcasedJurisdiction)
  ) {
    return upcasedJurisdiction;
  }

  return undefined;
}

/**
 * Returns the UTM fields derived from the `partner_` query parameters, or
 * `null` if there aren’t any such parameters.
 *
 * @see PartnerCampaignQueryParameters
 */
export function queryToUtmFields(search: QueryComponent): UTMFields | null {
  // Use case insensitive parameter keys.
  //
  // Cast to `PartnerCampaignQueryParameters` so that we will get an error if
  // there’s a typo in the lookups below.
  const normalizedSearch: Partial<PartnerCampaignQueryParameters> = {};

  Object.keys(search).forEach((key: string) => {
    (normalizedSearch as any)[key.toLowerCase()] = search[key];
  });

  const utmFields = {
    partnerId: normalizedSearch['partner'] ?? '',
    partnerCampaign: normalizedSearch['partner_campaign'] ?? '',
    partnerContent: normalizedSearch['partner_content'] ?? '',
    partnerCustom: normalizedSearch['partner_custom'] ?? '',
    partnerMedium: normalizedSearch['partner_medium'] ?? '',
    partnerTerm: normalizedSearch['partner_term'] ?? '',
  };

  // Check and see if we actually _got_ anything. Important because we want to
  // cache UTM fields if we ever see them, even if clicking around the site
  // makes them disappear.
  if (Object.values(utmFields).find((v) => v !== '')) {
    return utmFields;
  } else {
    return null;
  }
}

/**
 * Wrapper around query-string’s `parse` that collapses arrays down to just
 * their first element.
 *
 * Effectively ignores later parameters of the same name as ones that have
 * already come up.
 *
 * Also applies a proper type, rather than the default `any`.
 */
export function parseQueryString(str: string): {
  [k: string]: string;
} {
  // By default the query-string library will create arrays when the same key
  // appears multiple times. We don’t need that and it plays havok with types.
  const params = qs.parse(str) as { [k: string]: string | string[] };

  return Object.fromEntries(
    Object.entries(params).map(([key, val]) => [
      key,
      // We know if it’s an array it must have at least two items, so it’s safe
      // to take the first.
      Array.isArray(val) ? val[0]! : val,
    ])
  );
}
