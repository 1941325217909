import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const SkipToMainContentLink: React.FC<{}> = () => (
  <a
    className="absolute left-[-9999px] focus:left-1 top-1 p-1 text-lg z-[999] bg-white"
    href="#main-content"
  >
    <FormattedMessage
      defaultMessage="Skip to main content"
      description="Link to help screen reader and keyboard users quickly navigate to page's main content"
      id="wrapper.skipToMainContent"
    />
  </a>
);
