import { QueryClient, queryOptions } from '@tanstack/react-query';
import moment from 'moment';

import type {
  JurisdictionBooleanConfig,
  JurisdictionConfig,
  NationalBooleanConfig,
  NationalLocalesConfig,
  SitewideAlertsObject,
  TerritoryConfig,
} from '../contentful/types';
import type { Jurisdiction } from '../data/jurisdictions';
import type { Option } from '../utils/option';

import { ContentfulService } from './contentful-service';

/**
 * Wrapper around {@link ContentfulService} that also uses caching via
 * {@link QueryClient}.
 *
 * Note: we return `undefined` for backwards-compatibility, but have to store
 * `null` in {@link QueryClient} due to react-query’s behavior:
 * https://tanstack.com/query/v4/docs/framework/react/guides/migrating-to-react-query-4#undefined-is-an-illegal-cache-value-for-successful-queries
 */
export class VoterEducationService {
  private contentfulService: ContentfulService;
  private queryClient: QueryClient;

  constructor({
    contentfulService = new ContentfulService(),
    queryClient,
  }: {
    contentfulService?: ContentfulService;
    queryClient: QueryClient;
  }) {
    this.contentfulService = contentfulService;
    this.queryClient = queryClient;
  }

  public async fetchJurisdictionConfig(
    stateCode: Jurisdiction,
    isPreview: boolean,
    useTestConfig: boolean,
    today: moment.Moment
  ): Promise<Option<JurisdictionConfig>> {
    return (
      (await this.queryClient.fetchQuery({
        staleTime: 5 * 60 * 1000,
        queryKey: [
          'fetchJurisdictionConfig',
          stateCode,
          isPreview,
          useTestConfig,
          today.format('YYYY-MM-DD'),
        ],
        queryFn: async () =>
          (await this.contentfulService.fetchJurisdictionConfig(
            stateCode,
            isPreview,
            useTestConfig,
            today
          )) ?? null,
      })) ?? undefined
    );
  }

  public async fetchTerritoryConfig(
    territoryCode: Jurisdiction,
    isPreview: boolean,
    useTestConfig: boolean
  ): Promise<Option<TerritoryConfig>> {
    return (
      (await this.queryClient.fetchQuery({
        staleTime: 5 * 60 * 1000,
        queryKey: [
          'fetchTerritoryConfig',
          territoryCode,
          isPreview,
          useTestConfig,
        ],
        queryFn: () =>
          this.contentfulService.fetchTerritoryConfig(
            territoryCode,
            isPreview,
            useTestConfig
          ) ?? null,
      })) ?? undefined
    );
  }

  public async fetchSitewideAlerts(
    isPreview: boolean
  ): Promise<Option<SitewideAlertsObject>> {
    return (
      (await this.queryClient.fetchQuery(
        this.sitewideAlertsQueryOptions(isPreview)
      )) ?? undefined
    );
  }

  /**
   * react-query {@link QueryOptions} for loading sitewide alerts, so that we
   * can be consistent between {@link fetchSitewideAlerts} and loading this data
   * via a {@link useQuery} hook.
   *
   * Note that these options return `null` instead of `undefined` to match with
   * react-query’s semantics.
   */
  public sitewideAlertsQueryOptions(isPreview: boolean) {
    return queryOptions({
      staleTime: 5 * 60 * 1000,
      queryKey: ['fetchSitewideAlerts', isPreview],
      queryFn: async () =>
        (await this.contentfulService.fetchSitewideContent(isPreview)) ?? null,
    });
  }

  public async fetchJurisdictionBooleanConfig(
    stateCode: Jurisdiction,
    isPreview: boolean,
    today: moment.Moment
  ): Promise<JurisdictionBooleanConfig> {
    return this.queryClient.fetchQuery({
      staleTime: 5 * 60 * 1000,
      queryKey: [
        'fetchJurisdictionBooleanConfig',
        stateCode,
        isPreview,
        today.format('YYYY-MM-DD'),
      ],
      queryFn: () =>
        this.contentfulService.fetchJurisdictionBooleanConfig(
          stateCode,
          isPreview,
          today
        ),
    });
  }

  public fetchNationalConfigs(
    isPreview: boolean,
    today: moment.Moment
  ): Promise<[NationalBooleanConfig, NationalLocalesConfig]> {
    return this.queryClient.fetchQuery({
      staleTime: 5 * 60 * 1000,
      queryKey: ['fetchNationalConfigs', isPreview, today.format('YYYY-MM-DD')],
      queryFn: () =>
        this.contentfulService.fetchNationalConfigs(isPreview, today),
    });
  }
}

export type VoterEducationProvider = {
  voterEducation: VoterEducationService;
};
