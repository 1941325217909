import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { JurisdictionHotlineFooterOverride } from '@dnc/baseline/contentful/types';
import type { Option } from '@dnc/baseline/utils/option';

import { HOTLINE_SMS_BODY_MSG } from '../pages/header';

import { LocalizedRichText } from './LocalizedRichText';

const year = new Date().getFullYear();

type FooterProps = {
  hotlineFooterOverride: Option<JurisdictionHotlineFooterOverride>;
};

export const Footer: React.FC<FooterProps> = ({ hotlineFooterOverride }) => {
  const intl = useIntl();
  const hotlineSmsBodyText = intl.formatMessage(HOTLINE_SMS_BODY_MSG);

  return (
    <footer className="flex flex-col gap-4">
      <a
        href="https://www.fvap.gov/"
        target="_blank"
        className="abroad"
        rel="noopener noreferrer"
      >
        <FormattedMessage
          defaultMessage="Voting While Living Abroad"
          id="footer.votingAbroad"
          tagName="span"
        />
      </a>
      <ul>
        <li>
          <a
            href="https://www.democrats.org/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              defaultMessage="Terms of Service"
              id="footer.tos"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.democrats.org/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              defaultMessage="Privacy Policy"
              id="footer.privacy"
            />
          </a>
        </li>
      </ul>
      <div>
        <FormattedMessage
          defaultMessage="Paid for by the Democratic National Committee {dncPhoneNumber}"
          id="footer.disclaimerHtml"
          values={{
            dncPhoneNumber: (
              <a href="tel:2028638000" className="no-wrap" dir="ltr">
                {'(202) 863-8000'}
              </a>
            ),
          }}
        />
        <br />
        <FormattedMessage
          defaultMessage="This communication is not authorized by any candidate or candidate’s committee."
          id="footer.notAuthorized"
        />
        <br />
        {hotlineFooterOverride ? (
          <div id="sms-disclaimer">
            <LocalizedRichText
              richTextBlock={hotlineFooterOverride?.footerOverrideCopy?.copy}
              className="sms-disclaimer"
            />
          </div>
        ) : (
          <p id="sms-disclaimer">
            <FormattedMessage
              defaultMessage="By texting {smsBody} to 70888, you are consenting to receive recurring and/or automated text messages & calls from the DNC with news & info about our work. For SMS, message & data rates may apply. Text STOP to end. Text HELP for help."
              id="footer.access"
              values={{
                smsBody: hotlineSmsBodyText,
              }}
            />
          </p>
        )}
        <br />
        <FormattedMessage
          defaultMessage="Copyright © {year} DNC Services Corporation"
          id="footer.copyright"
          values={{
            year: year,
          }}
        />
      </div>
    </footer>
  );
};
