import * as React from 'react';
import { IntlContext, defineMessages } from 'react-intl';

import { Links, Meta, Scripts, ScrollRestoration } from 'react-router';

import { LocaleContext } from '@dnc/shared-components/LocaleContext';

import GTAG_SCRIPT from './scripts/gtag.cjs?raw';
import MOUSEFLOW_SCRIPT from './scripts/mouseflow.cjs?raw';

/**
 * These messages are used in the {@link IndexHtml} component. We’re not
 * using the React components for this because these go into attributes or
 * elements that require string content only.
 */
const MESSAGES = defineMessages({
  title: {
    defaultMessage: 'I Will Vote',
    id: 'wrapper.appName',
  },
  description: {
    defaultMessage:
      'Be a voter. Find everything you need to vote in your state’s next election.',
    id: 'Ym8AwK',
    description: 'Generic meta description for the site',
  },
});

/**
 * Adds our gtag.js script to the page. We factor this out so that we can delay
 * it until after the first route has loaded.
 *
 * Adding this script will cause other scripts to be added for each registered
 * tracking ID (currently 3… see `scripts/gtag.cjs`). Those can both mess with
 * React’s hydration, and also they take loading and parsing time that slows the
 * page down just as it’s loading, causing a delay between geo-ip being fetched
 * and Contentful being fetched.
 *
 * @returns A cleanup function, making this appropriate to pass to useEffect.
 */
export function addGtagScriptTag() {
  const head = document.querySelector('head');

  const gtagScriptTag = document.createElement('script');
  gtagScriptTag.src =
    'https://www.googletagmanager.com/gtag/js?id=G-7LFDEYB4LE';
  gtagScriptTag.async = true;

  head?.appendChild(gtagScriptTag);

  return () => {
    head?.removeChild(gtagScriptTag);
  };
}

/**
 * Our overall HTML structure for IWV. This will be pre-rendered into index.html
 * and index.es.html files that can be served by CloudFront for IWillVote.com
 * and VoyAVotar.com, respectively.
 *
 * See the {@link Layout} component in root.tsx.
 */
export default function IndexHtml({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode {
  const locale = React.useContext(LocaleContext);
  const intl = React.useContext(IntlContext);

  let url: string;
  let socialImageUrl: string;

  if (locale === 'es') {
    url = 'https://voyavotar.com/';
    socialImageUrl = 'https://voyavotar.com/voyavotar-social.png';
  } else {
    url = 'https://iwillvote.com/';
    socialImageUrl = 'https://iwillvote.com/iwillvote-social.png';
  }

  return (
    <html lang={locale}>
      <head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta charSet="utf-8" />
        <meta httpEquiv="Content-Language" content={locale} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />

        <link
          rel="icon"
          href="/i-will-vote-favicon-16.png"
          sizes="16x16"
          type="image/x-icon"
        />
        <link
          rel="icon"
          href="/i-will-vote-favicon-32.png"
          sizes="32x32"
          type="image/x-icon"
        />
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <link rel="stylesheet" href="https://use.typekit.net/cbx3pps.css" />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: '@import url("https://use.typekit.net/cbx3pps.css");',
          }}
        />

        <meta
          property="og:title"
          content={intl.formatMessage(MESSAGES.title)}
        />
        <meta
          property="og:description"
          content={intl.formatMessage(MESSAGES.description)}
        />
        <meta property="og:image" content={socialImageUrl} />
        <meta property="og:url" content={url} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={url} />
        <meta name="twitter:creator" content="@TheDemocrats" />
        <meta
          name="twitter:title"
          content={intl.formatMessage(MESSAGES.title)}
        />
        <meta name="twitter:image" content={socialImageUrl} />
        <meta
          name="twitter:description"
          content={intl.formatMessage(MESSAGES.description)}
        />

        <title>{intl.formatMessage(MESSAGES.title)}</title>

        <meta
          name="description"
          content={intl.formatMessage(MESSAGES.description)}
        />

        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: GTAG_SCRIPT }}
        />

        <script
          type="text/javascript"
          defer
          src="//cdn.mouseflow.com/projects/9092f611-fa9c-4a18-8277-74aac5afa4f0.js"
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: MOUSEFLOW_SCRIPT }}
        />

        <Meta />
        <Links />
      </head>

      <body>
        <main id="root">
          {children}

          <ScrollRestoration />
          <Scripts />
        </main>
      </body>
    </html>
  );
}
