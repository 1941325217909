import * as React from 'react';
import type { Location } from 'react-router';

import type { Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import type { AnalyticsService } from '@dnc/baseline/services/analytics-service';
import type { Option } from '@dnc/baseline/utils/option';

import { LocaleContext } from '@dnc/shared-components/LocaleContext';

import { parseQueryString, queryToUtmFields } from '../services/utils';

const AnalyticsContext = React.createContext<AnalyticsService | null>(null);

/**
 * Returns the globally-provided {@link AnalyticsService} from
 * {@link AnalyticsProvider}.
 *
 * Throws an Error if no AnalyticsProvider was set.
 */
export function useAnalytics(): AnalyticsService {
  const analytics = React.useContext(AnalyticsContext);

  if (!analytics) {
    throw new Error('useAnalytics called outside of an AnalyticsProvider');
  }

  return analytics;
}

/**
 * Provider that updates an {@link AnalyticsService} instance with the latest
 * language, jurisdiction, and routes, and also provides it to child components
 * through a React {@link Context}.
 *
 * @see useAnalytics
 */
export const AnalyticsProvider: React.FC<{
  analytics: AnalyticsService;
  location: Location;
  jurisdiction: Option<Jurisdiction>;
  children?: React.ReactNode;
}> = ({ analytics, children, location, jurisdiction }) => {
  const locale = React.useContext(LocaleContext);
  const analyticsRef = React.useRef(analytics);

  // We use useLayoutEffect mostly to try to make sure these updates happen
  // before any useEffects in child components, so they report accurate
  // analytics info.
  //
  // Behind a conditional to suppress SSR warnings about useLayoutEffect not
  // rendering on the server.
  //
  // TODO(fiona): Re-work this to avoid using useLayoutEffect.
  if (!import.meta.env.SSR) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useLayoutEffect(() => {
      analyticsRef.current = analytics;
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useLayoutEffect(() => {
      analyticsRef.current.setLocale(locale);
    }, [locale]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useLayoutEffect(() => {
      analyticsRef.current.setJurisdiction(jurisdiction);
    }, [jurisdiction]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useLayoutEffect(
      () => {
        analyticsRef.current.routeChanged(location, locale, jurisdiction);

        const utmFields = queryToUtmFields(parseQueryString(location.search));

        if (utmFields) {
          analyticsRef.current.setUtmFields(utmFields);
        }
      },
      // We only want to send routeChanged when pathname or search changed, not
      // any other location changes.
      //
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [location.pathname, location.search]
    );
  }

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const AnalyticsContextProviderForTest = AnalyticsContext.Provider;
