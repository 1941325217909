import { redirect } from 'react-router';

import type { JurisdictionConfig } from '@dnc/baseline/contentful/types';
import type { ActiveLocale } from '@dnc/baseline/utils/localization';

import { UrlHelper } from '../services/url-helper';

/**
 * Enforces that the locale is enabled for the current jurisdiction, throwing a
 * redirect if necessary.
 *
 * Has no effect if locale is null, since we assume that our default locales are
 * valid for all jurisdictions.
 *
 * Routes are responsible for calling this after successfully loading a
 * {@link JurisdictionConfig}. We don’t handle it generally because if the
 * various ways that the jurisdiction is determined, and how other locale
 * handling is very top-level (in Layout).
 */
export function enforceJurisdictionLocale(
  url: string,
  locale: ActiveLocale | null,
  jurisdictionConfig: JurisdictionConfig
) {
  if (locale && !jurisdictionConfig.supportedLocales.includes(locale)) {
    // query parameter is a locale we understand, but it’s not valid for the
    // current jurisdiction, so remove it
    throw redirect(
      UrlHelper.changeRequestUrlParams(url, {
        [UrlHelper.LOCALE_PARAM]: undefined,
      })
    );
  }
}
