import * as React from 'react';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router';

import type { JurisdictionHotlineFooterOverride } from '@dnc/baseline/contentful/types';
import {
  Jurisdictions,
  type Jurisdiction,
} from '@dnc/baseline/data/jurisdictions';
import { ActiveLanguageToLocale } from '@dnc/baseline/utils/localization';
import type { Option } from '@dnc/baseline/utils/option';

import { LocaleContext } from '@dnc/shared-components/LocaleContext';
import { LocalizedRichText } from '@dnc/shared-components/LocalizedRichText';

import EnLogo from '../../assets/i-will-vote-logo-we.svg?react';
import EsLogo from '../../assets/voy-a-votar-logo.svg?react';
import { UrlHelper } from '../../services/url-helper';

type HeaderProps = {
  jurisdiction: Option<Jurisdiction>;
  customVoterHotline: Option<string>;
  hotlineFooterOverride: Option<JurisdictionHotlineFooterOverride>;
  locateLayout?: boolean;
};

export const HOTLINE_SMS_BODY_MSG = defineMessage({
  defaultMessage: 'VOTE',
  id: 'mQzbfB',
  description:
    'The body of the SMS sent to 70888 to get a response. Varies by language so we can respond in the right language.',
});

export const Header: React.FC<HeaderProps> = ({
  jurisdiction,
  customVoterHotline,
  hotlineFooterOverride,
  locateLayout,
}) => {
  const intl = useIntl();
  const locale = React.useContext(LocaleContext);
  const location = useLocation();
  const Logo = locale === ActiveLanguageToLocale['Spanish'] ? EsLogo : EnLogo;

  const urlHelper = UrlHelper.fromLocation(location);

  const logoAriaLabel = intl.formatMessage({
    defaultMessage: 'I Will Vote homepage',
    id: 'HHpunC',
  });

  const hotlineSmsBodyText = intl.formatMessage(HOTLINE_SMS_BODY_MSG);

  const currentJurisdictionName = Jurisdictions.isJurisdiction(jurisdiction)
    ? Jurisdictions.localized(jurisdiction, intl)
    : undefined;
  if (locateLayout) {
    return (
      <header className="header">
        <div className="header__inner">
          <Link aria-label={logoAriaLabel} to={urlHelper.homeURL(jurisdiction)}>
            <Logo aria-hidden="true" className="logo header__logo" />
          </Link>
          <div className="header__content">
            {jurisdiction ? (
              <div className="header__current-state">
                {currentJurisdictionName}
              </div>
            ) : null}
          </div>
        </div>
      </header>
    );
  }
  // This function chooses which hotline will be displayed in the header.
  // Display options are the custom voter hotline, a hotline override, or neither (the default hotline).
  function displayHotlineLayout(
    jurisdiction: Option<Jurisdiction>,
    customVoterHotline: Option<string>,
    hotlineFooterOverride: Option<JurisdictionHotlineFooterOverride>
  ) {
    if (jurisdiction && customVoterHotline) {
      return (
        <FormattedMessage
          defaultMessage="Call the {jurisdiction} voter hotline at {hotline} or text <sms-link>{smsBody} to 70888</sms-link> for answers to some common voting questions."
          values={{
            smsBody: hotlineSmsBodyText,
            'sms-link': (chunks) => (
              <a
                href={`sms://70888&body=${hotlineSmsBodyText}`}
                className="no-wrap"
              >
                {chunks}
              </a>
            ),
            hotline: (
              <a href={`tel:${customVoterHotline}`} className="no-wrap">
                {customVoterHotline}
              </a>
            ),
            jurisdiction: currentJurisdictionName,
          }}
          id="OhpnmQ"
        />
      );
    } else if (hotlineFooterOverride) {
      return (
        <div className="text-lilac-800 text-sm my-4 lg:text-base">
          <LocalizedRichText
            richTextBlock={hotlineFooterOverride.hotlineOverrideCopy?.copy}
            className="text-sm my-4 lg:text-base"
            innerParagraphStyleOverride="text-lilac-800"
          />
        </div>
      );
    } else {
      return (
        <FormattedMessage
          defaultMessage="Call our voter hotline at {hotline} or text <sms-link>{smsBody} to 70888</sms-link> for answers to some common voting questions."
          values={{
            smsBody: hotlineSmsBodyText,
            'sms-link': (chunks) => (
              <a
                href={`sms://70888&body=${hotlineSmsBodyText}`}
                className="no-wrap"
              >
                {chunks}
              </a>
            ),
            hotline: (
              <a href="tel:1-833-336-8683" className="no-wrap">
                833-336-8683
              </a>
            ),
          }}
          id="HllheH"
        />
      );
    }
  }
  return (
    <div className="flex flex-col gap-2 pb-1 lg:gap-3">
      <Link aria-label={logoAriaLabel} to={urlHelper.homeURL(jurisdiction)}>
        <Logo aria-hidden="true" className="logo" />
      </Link>

      <div className="text-lilac-800 text-sm my-4 lg:text-base">
        <FormattedMessage
          defaultMessage="Have questions about voting?"
          id="wrapper.haveQuestionsHtml.questionsAboutVoting"
          tagName="strong"
        />
        <br />
        {displayHotlineLayout(
          jurisdiction,
          customVoterHotline,
          hotlineFooterOverride
        )}
        <br />
        <a href="#sms-disclaimer">
          <FormattedMessage
            defaultMessage="Disclaimer below."
            id="wrapper.haveQuestionsHtml.disclaimer"
          />
        </a>
      </div>
    </div>
  );
};
