import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ActiveJurisdictions } from '@dnc/baseline/data/jurisdiction-denylist';
import {
  Jurisdictions,
  type Jurisdiction,
} from '@dnc/baseline/data/jurisdictions';

type JurisdictionSelectProps = {
  residentPrefix?: boolean;
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  id?: string;
  name: string;
  onChange: React.FormEventHandler<HTMLSelectElement>;
  placeholder?: React.ReactNode;
  required?: boolean;
  optionTemplate?: React.ReactNode;
  value: Jurisdiction | '';
};

const JurisdictionSelect: React.FC<JurisdictionSelectProps> = (props) => {
  // Jurisdiction codes do not have the same alphabetical
  // order as their names. Order should be determined by the
  // localized jurisdiction name, not the two-letter code.
  // So, first map the labels, then sort.
  const intl = useIntl();
  const options = ActiveJurisdictions.map((jurisdiction: Jurisdiction) => {
    // e.g. "DC" becomes "District of Columbia"
    const localizedJurisdiction = Jurisdictions.localized(jurisdiction, intl);

    return {
      value: jurisdiction,
      nameForSort: localizedJurisdiction,
      label: localizedJurisdiction,
    };
  }).sort((a, b) => (a.nameForSort > b.nameForSort ? 1 : -1));

  return (
    <>
      {props.residentPrefix && (
        <label
          htmlFor={props.id || props.name}
          className="text-base text-blue-100 font-bold my-0"
        >
          <FormattedMessage
            defaultMessage="I’m a resident of:"
            description="Label for jurisdiction select dropdown"
            id="bVZorx"
          />
        </label>
      )}
      <select
        autoComplete={props.autoComplete}
        className={props.className}
        disabled={props.disabled}
        name={props.name}
        id={props.id || props.name}
        onChange={props.onChange}
        required={props.required}
        value={props.value}
      >
        {props.placeholder && (
          <option value="" disabled>
            {props.placeholder}
          </option>
        )}
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

export { JurisdictionSelect };
