import { type Jurisdiction, Jurisdictions } from '../data/jurisdictions';
import {
  getJurisdictionSettings,
  type JurisdictionSettings,
} from '../jurisdiction-info/jurisdiction-settings';

class NoInfoError extends Error {}

export class JurisdictionSettingsService {
  public static getInfo(
    jurisdiction: Jurisdiction
  ): Readonly<JurisdictionSettings> {
    if (Jurisdictions.allStringsEn[jurisdiction]) {
      const settings = getJurisdictionSettings(jurisdiction);
      return Object.freeze(settings);
    }
    throw new NoInfoError(`Unknown jurisdiction: ${jurisdiction}`);
  }
}
export type JurisdictionSettingsProvider = {
  settings: JurisdictionSettingsService;
};
