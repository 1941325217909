/*
 */

import { Jurisdictions, type Jurisdiction } from '../data/jurisdictions';
import type { JurisdictionButton } from '../utils/jurisdiction-button';

import { getCustomButtons } from './external-links/custom-buttons';
import { jurisdictionURLs, type ExternalURLs } from './external-links/urls';

const JursidictionLongNames = Object.values(Jurisdictions.allStringsEn);

const ShowPrecinct: Jurisdiction[] = ['NV'];

export type JurisdictionSettings = {
  abbreviation: Jurisdiction;
  longName: (typeof JursidictionLongNames)[number];
  customButtons: JurisdictionButton[];
  showPrecinct: boolean;
  urls: ExternalURLs;
};

export const getJurisdictionSettings = (
  jurisdiction: Jurisdiction
): JurisdictionSettings => ({
  abbreviation: jurisdiction,
  longName: Jurisdictions.allStringsEn[jurisdiction],
  customButtons: getCustomButtons(jurisdiction),
  showPrecinct: ShowPrecinct.includes(jurisdiction),
  urls: Jurisdictions.isState(jurisdiction)
    ? jurisdictionURLs[jurisdiction]
    : ({} as ExternalURLs),
});
