import moment from 'moment';
import * as React from 'react';

import type {
  JurisdictionHotlineFooterOverride,
  JurisdictionLocaleSupport,
  LinkedAlertFields,
} from '@dnc/baseline/contentful/types';
import type { Jurisdiction } from '@dnc/baseline/data/jurisdictions';
import { localeReadsRightToLeft } from '@dnc/baseline/utils/localization';
import type { Option } from '@dnc/baseline/utils/option';

import { Footer } from '@dnc/shared-components/Footer';
import { LocaleContext } from '@dnc/shared-components/LocaleContext';
import { RichTextAlertBanner } from '@dnc/shared-components/RichTextAlertBanner';

import { SiteLanguageMenu } from '../SiteLanguageMenu';
import { SkipToMainContentLink } from '../SkipToMainContentLink';
import {
  type BackupAlert,
  BackupAlertBanner,
} from '../shared/BackupAlertBanner';

import { Layout } from './layout';

type PageProps = {
  jurisdiction: Option<Jurisdiction>;
  customVoterHotline: Option<string>;
  hotlineFooterOverride: Option<JurisdictionHotlineFooterOverride>;
  locateLayout?: boolean;
  sitewideAlerts: Array<LinkedAlertFields> | undefined;
  jurisdictionAlerts: Array<LinkedAlertFields> | undefined;
  today?: moment.Moment;
  supportedLocales: JurisdictionLocaleSupport;
  priorityLocales: JurisdictionLocaleSupport;
  children?: React.ReactNode;
};

export function findActiveAlert(
  submittedAlerts: Array<LinkedAlertFields> | undefined,
  today: moment.Moment
) {
  return submittedAlerts?.find(
    (alert) =>
      today.isSameOrAfter(alert.startDate.en, 'day') &&
      today.isSameOrBefore(alert.endDate.en, 'day')
  );
}

export function findActiveBackupAlert(
  backupAlert: BackupAlert,
  today: moment.Moment
) {
  // This method takes a backup alert's start and end dates
  // into consideration, so even hard-coded
  // alerts won't require special efforts to
  // take down when they should be 'deactivated'.
  if (
    today.isSameOrAfter(backupAlert.startDate, 'day') &&
    today.isSameOrBefore(backupAlert.endDate, 'day')
  ) {
    return backupAlert.alertMessage;
  } else {
    return undefined;
  }
}

export const Page: React.FC<PageProps> = ({
  jurisdiction,
  customVoterHotline,
  hotlineFooterOverride,
  locateLayout,
  sitewideAlerts,
  jurisdictionAlerts,
  today = moment(),
  supportedLocales,
  priorityLocales,
  children,
}) => {
  /* With Contentful managed alerts, this page wrapper component
  holds the logic to find active alert(s). The RichTextAlertBanner itself
  is only responsible for displaying what it is given.
  */
  const activeSitewideAlert = findActiveAlert(sitewideAlerts, today);
  const activeJurisdictionAlert = findActiveAlert(jurisdictionAlerts, today);

  /* 
   If Contentful becomes unresponsive, we want to be able to spin up
   "hard-coded" alert banners independent from the Contentful service. 
   By using the BackupAlertBanner component, IWV can render a LocalizedMessage
   either pulling from already configured alert messages, found in 
   src/baseline/jurisdiction-info/alert-banners/alert-messages.ts,
   or by directly hard coding a message here.
  */
  const useBackupAlertBanner = false;
  const backupAlertMessage = {
    startDate: moment('1900-01-01'),
    endDate: moment('1900-01-01'),
    alertMessage: {
      ar: '',
      en: '',
      es: '',
      ko: '',
      tl: '',
      vi: '',
      'zh-Hans': '',
    },
  };
  const activeBackupAlert = findActiveBackupAlert(backupAlertMessage, today);
  const currentLocale = React.useContext(LocaleContext);
  const displayRightToLeft = localeReadsRightToLeft(currentLocale);
  return (
    <div lang={currentLocale} dir={displayRightToLeft ? 'rtl' : ''}>
      <SkipToMainContentLink />

      <SiteLanguageMenu
        currentLocale={currentLocale}
        supportedLocales={supportedLocales}
        priorityLocales={priorityLocales}
      />
      {activeSitewideAlert && (
        <RichTextAlertBanner
          alert={activeSitewideAlert}
          className="alert-bar"
        />
      )}

      {activeJurisdictionAlert && (
        <RichTextAlertBanner
          alert={activeJurisdictionAlert}
          className="alert-bar"
        />
      )}

      {useBackupAlertBanner && activeBackupAlert && (
        <BackupAlertBanner alert={activeBackupAlert} className="alert-bar" />
      )}

      <Layout
        jurisdiction={jurisdiction}
        locateLayout={locateLayout}
        customVoterHotline={customVoterHotline}
        hotlineFooterOverride={hotlineFooterOverride}
      >
        {children}
      </Layout>

      <Footer hotlineFooterOverride={hotlineFooterOverride} />
    </div>
  );
};
