import { QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';
import { Outlet, useLocation } from 'react-router';

import {
  DEFAULT_LOCALES,
  defaultHostLocale,
} from '@dnc/baseline/utils/localization';

import type { Route } from './+types/root';

import IndexHtml, { addGtagScriptTag } from './components/layout/IndexHtml';
import { Page } from './components/pages/page';
import IwvErrorBoundary from './components/routes/ErrorBoundary';
import { IwvIntlProvider } from './i18n/i18n-config';

import { getGlobalServices } from './services/services';
import { TypedUrlHelper } from './services/url-helper';

// For Tailwind. Comes first due to reset.
import './styles/app.css';

// Existing, pre-Tailwind SASS styles.
import './scss/main.scss';

/**
 * Root page structure for the entire app.
 *
 * The main content, hydration fallback, and error boundary components will all
 * be rendered within this. The index.html and index.es.html files generated
 * during the build are this component with the {@link HydrateFallback}
 * component rendered inside of it.
 *
 * We use this component to set up our locale providers since they’re at the
 * root of the entire app.
 */
export function Layout({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode {
  const location = useLocation();
  const urlHelper = TypedUrlHelper.fromLocation(location);

  return (
    // TODO(fiona): Bring the browser’s requested locales into this.
    <IwvIntlProvider locale={urlHelper.getLocale() ?? defaultHostLocale()}>
      <IndexHtml>{children}</IndexHtml>
    </IwvIntlProvider>
  );
}

/**
 * Renders the very default IWV page skeleton while things are loading. This
 * HTML will be serialized into the index.html file.
 *
 * This matches the 2-column layout used on the home page and most of the site.
 * For other pages (like location results) there will be a more significant
 * change when the UI switches from this to the "locate" layout.
 */
export function HydrateFallback(
  _: Route.HydrateFallbackProps
): React.ReactNode {
  return (
    <Page
      jurisdiction={undefined}
      customVoterHotline={undefined}
      hotlineFooterOverride={undefined}
      jurisdictionAlerts={undefined}
      priorityLocales={DEFAULT_LOCALES}
      sitewideAlerts={undefined}
      supportedLocales={DEFAULT_LOCALES}
    >
      <div className="loading-spinner" />
    </Page>
  );
}

/**
 * Component to render (and report to Sentry) when there are errors in loading
 * our data.
 */
export function ErrorBoundary(_: Route.ErrorBoundaryProps): React.ReactNode {
  const services = getGlobalServices();

  return <IwvErrorBoundary sentryService={services.sentry} />;
}

/**
 * Main wrapper around all of our non-error, non-static components. Fairly bare
 * so that different parts of the app can have their own UI and all that.
 *
 * TODO(fiona): Move ConnectedPage things into here.
 */
export default function Root(_: Route.ComponentProps): React.ReactNode {
  const services = getGlobalServices();

  // Add the Google analytics scripts only after the first clientLoader has
  // succeeded. Dodges hydration issues and also keeps the loading of additional
  // <script> tags from slowing down the clientLoader process.
  //
  // (We know this is after the first clientLoader succeeds because until then,
  // RR is rendering <HydrateFallback>.)
  React.useEffect(addGtagScriptTag, []);

  return (
    <QueryClientProvider client={services.queryClient}>
      <Outlet />
    </QueryClientProvider>
  );
}
