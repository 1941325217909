/*
JurisdictionDenylist is a list of jurisdiction that are not supported in
any IWV flow for whatever (lawyerly or timely) reason.

For convenience, ActiveJurisdictions is the inverse, e.g. a safelist.
*/

import { type Jurisdiction, Jurisdictions } from './jurisdictions';

export const JurisdictionDenylist = Object.keys(
  Jurisdictions.territoriesStringsEn
) as Jurisdiction[];

export const ActiveJurisdictions = (
  Object.keys(Jurisdictions.allStringsEn) as Jurisdiction[]
)
  .filter((j) => !JurisdictionDenylist.includes(j))
  .sort();
